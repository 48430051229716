import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import tools from '../../js/tools'
import { BtnVer, BtnEliminar, BtnPDF, BtnXML, BtnMail } from '../common/table_buttons'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import { Sliders } from 'react-feather';
import Select from '../common/select-basic';
import Table from '../common/tabla'
import { Briefcase } from 'react-feather';

import { Box } from 'react-feather';


const Default = ({
    esRefresh, setEsRefresh
}) => {
    const navigate = useNavigate()
    const [esRefreshLoc, setEsRefreshLoc] = useState(true)
    const [filtros, setFiltros] = useState({
        sucursal: '',
    })

    const [catalogos, setCatalogos] = useState({
        ctgSuc: [],
    })

    const limpiarFiltro = () => {
        setFiltros((s) => ({ sucursal: '' }))
        setEsRefreshLoc((s) => !s)
        setCatalogos((s) => ({ ...s }))
    }

    const onSelectSuc = (datos) => {
        setFiltros((s) => ({ ...s, sucursal: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectEst = (datos) => {
        let estatus = document.getElementById('list_est').value
        setFiltros((s) => ({ ...s, estatus }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectFecha = (datos) => {
        setFiltros((s) => ({ ...s, fecha: datos }))
        setEsRefreshLoc((s) => !s)
    }



    const colorSelector = (val) => {
        switch (val) {
            case 0:
                return '#dc3545'
            case 1:
                return ''
            case 2:
                return '#ffc107'
            case 3:
                return '#fd7e14'
            case 4:
                return '#17a2b8'
            case 5:
                return '#28a745'
            default:
                break;
        }
    }

    function descargarFacturaPDF(cliente, uuid) {
        notie.alert({
            type: 'success',
            text: `Descargando archivo, esto puede tardar unos momentos.`,
            time: 4,
            position: 'top'
        })
        fetch('rt_descargar_factura_pdf', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ cliente, uuid })
        })
            .then((response) => response.blob())
            .then(async (response) => {
                if (response.estatus == 'ERROR-DESCARGA') {
                    return notie.alert({
                        type: 'error',
                        text: `No es posible descargar el archivo en este momento.`,
                        time: 3,
                        position: 'top'
                    })
                }
                const url = window.URL.createObjectURL(response)
                const a = document.createElement('a')
                a.href = url
                a.download = `Invoice-${uuid}.pdf`
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
    }

    function descargarFacturaXML(cliente, uuid) {
        notie.alert({
            type: 'success',
            text: `Descargando archivo, esto puede tardar unos momentos.`,
            time: 4,
            position: 'top'
        })
        fetch('rt_descargar_factura_xml', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ cliente, uuid })
        })
            .then((response) => response.json())
            .then(async (response) => {
                if (response.estatus == 'ERROR-XML') {
                    return notie.alert({
                        type: 'error',
                        text: `No es posible descargar el archivo en este momento.`,
                        time: 3,
                        position: 'top'
                    })
                }
                const blob = new Blob([response.xml.XML], { type: 'application/xml' });
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.style.display = 'none';
                a.href = url
                a.download = `${uuid}.xml`;
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url);
            })
    }

    function reenviarFactura(cliente, uuid, correo) {
        notie.alert({
            type: 'success',
            text: `Descargando archivo, esto puede tardar unos momentos.`,
            time: 4,
            position: 'top'
        })
        fetch('rt_reenviar_factura', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ cliente, uuid, correo })
        })
            .then((response) => response.json())
            .then(async (response) => {
                console.log(response)
                if (response.estatus == 'ERROR-XML') {
                    return notie.alert({
                        type: 'error',
                        text: `No es posible descargar el archivo en este momento.`,
                        time: 3,
                        position: 'top'
                    })
                }
            })
    }

    useEffect(() => {
        tools.toolFetch({
            ruta: 'rt_ctg_suc_todas',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgSuc: response.datos.ctgSuc }))
        })

    }, [])

    useEffect(() => {
        setEsRefreshLoc((s) => !s)
    }, [esRefresh])



    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se eliminara <strong>${d.NOMBRE_EM}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_empresas_eliminar',
                    method: 'POST',
                    body: { id: d.ID_EM },
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = falses
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    setEsRefresh((s) => !s)
                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    return <Fragment>
        <Breadcrumb icon={Briefcase} title={'Facturas'} parent={['facturas']} />
        <div className="card">
            <div className="col-xl-12 col-md-12">
                {/* <div className="card-header d-flex justify-content-center p-3">
                    <h4><strong>EMPRESAS PRINCIPALES</strong></h4>
                </div> */}

                <div className='row'>
                    <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                        <Fragment>
                            <FilterRemove
                                className={'m-1'}
                                style={{ cursor: 'pointer', fontSize: 14 }}
                                onClick={() => limpiarFiltro()}
                            />
                            <Filtros
                                title={<Fragment>
                                    <Sliders size={14} /> Filtros</Fragment>}
                                items={[
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label className="form-label">Filtrar por sucursal</label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Sucursal"
                                                    placeholder="Seleccionar sucursal"
                                                    id="list_suc"
                                                    name="list_suc"
                                                    tipoValue={1}
                                                    value={filtros.sucursal ? filtros.sucursal : ''}
                                                    datos={catalogos.ctgSuc}
                                                    onChange={onSelectSuc}
                                                />
                                            </div>
                                    },
                                    {
                                        element:
                                            <div>
                                                <label className="form-label">Filtrar por estatus</label>
                                                {/* <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Entrada"
                                                    placeholder="Seleccionar estatus"
                                                    id="list_est"
                                                    name="list_est"
                                                    tipoValue={0}
                                                    value={filtros.estatus ? filtros.estatus : ''} 
                                                    datos={catalogos.ctgEstatus}
                                                    onChange={onSelectEstatus}
                                                /> */}


                                                <select
                                                    className="form-control form-control-md border"
                                                    data-label="Estatus"
                                                    id="list_est"
                                                    name="list_est"
                                                    value={filtros.estatus ? filtros.estatus : ''}
                                                    onChange={onSelectEst}
                                                >
                                                    <option value={''} defaultValue>Seleccionar estatus</option>
                                                    <option value={'1'} >Timbrado</option>
                                                    <option value={'0'} >Cancelado</option>
                                                </select>
                                            </div>
                                    },
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label htmlFor="buscar">
                                                    Filtrar por fecha
                                                </label>
                                                <input
                                                    id="fecha"
                                                    name="fecha"
                                                    type="date"
                                                    className="form-control"
                                                    value={filtros.fecha ? filtros.fecha : ''}
                                                    onChange={(e) => {
                                                        onSelectFecha(e.target.value)
                                                    }}
                                                />
                                            </div>
                                    },

                                ]}
                            />
                        </Fragment>
                    </div>
                    < div className="mt-3" >
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <button
                                    className="btn btn-purple btn-nuevo"
                                    type="button"
                                    onClick={() => {
                                        navigate('/facturas/nuevo')
                                    }}
                                > Nueva factura
                                </button>
                                }
                            headers={[
                                {
                                    nombre: 'ID', tipo: 'html', texto: 'ID_FAC', html: (d) => {
                                        return <div>{d.ID_FAC}</div>
                                    }
                                },
                                {
                                    nombre: 'UUID', tipo: 'html', texto: 'UUID_FAC', html: (d) => {
                                        return <div>{d.UUID_FAC}</div>
                                    }
                                },
                                {
                                    nombre: 'SOLICITUD', tipo: 'html', texto: 'FOLIO_SOL_FAC', html: (d) => {
                                        return <div>{d.FOLIO_SOL_FAC}</div>
                                    }
                                },
                                {
                                    nombre: 'CLIENTE', tipo: 'html', texto: 'CLIENTE', html: (d) => {
                                        return <div>{d.CLIENTE}</div>
                                    }
                                },
                                {
                                    nombre: 'FECHA', tipo: 'html', texto: 'FECHA', html: (d) => {
                                        return <div>{d.FECHA}</div>
                                    }
                                },
                                {
                                    nombre: 'SUCURSAL', tipo: 'html', texto: 'NOMBRE_SUC', html: (d) => {
                                        return <div>{d.NOMBRE_SUC}</div>
                                    }
                                },

                                {
                                    nombre: 'ESTATUS', tipo: 'html', texto: 'ESTATUS_FAC', html: (d) => {
                                        return <>
                                            <div>
                                                <div className='row'>
                                                    {d.ESTATUS_FAC != '' || d.ESTATUS_FAC != null ?
                                                        <div className='col-md-6 p-0'>
                                                            <div className="card border-secondary mb-1 text-center">
                                                                <div className="card-body text-secondary p-0">
                                                                    <strong className="card-title p-0 m-0" style={{ fontSize: 14, color: colorSelector(d.ESTATUS_FAC) }}>{d.TEXTO_ESTATUS}</strong><br />
                                                                    {/* <p className="card-text" style={{fontSize: 12}}>{d.FECHA_ALTA_SE_TEXT}</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='col-md-6 p-0 text-center'>
                                                            Sin estatus
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return <div>
                                            <BtnXML
                                                onClick={() => descargarFacturaXML(d.ID_CL_FAC, d.UUID_FAC)}
                                            />
                                            <BtnPDF
                                                onClick={() => descargarFacturaPDF(d.ID_CL_FAC, d.UUID_FAC)}
                                            />
                                             <BtnMail
                                                onClick={() => reenviarFactura(d.ID_CL_FAC, d.UUID_FAC, d.CORREO_FAC)}

                                            />
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'}
                            path={'/rt_facturas'}
                            refresh={esRefreshLoc}
                            body={{ ...filtros }}
                            rowsPerPage={10}
                            paginadorTop={true}
                        >
                        </Table>
                    </div >
                </div>


            </div>
        </div>
    </Fragment>
}

export default Default


