
import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumbv2'
import Table from '../common/tabla'
import { Briefcase } from 'react-feather';
import { useNavigate } from 'react-router-dom'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import notie from 'notie'
import Tools from '../../js/tools'

const Default = () => {
    const navigate = useNavigate()
    const [esRefresh, setEsRefresh] = useState(true)

    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se eliminara <strong>${d.NOMBRE_EM}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_empresas_eliminar',
                    method: 'POST',
                    body: { id: d.ID_EM },
                }, (response) => {  
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    
                    setEsRefresh((s) => !s)
                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    return <Fragment>
        <Breadcrumb icon={Briefcase} title={'Carta Porte'} parent={['Carta Porte']} />
        <div className="card">
            <div className="col-xl-12 col-md-12">
                {/* <div className="card-header d-flex justify-content-center p-3">
                    <h4><strong>EMPRESAS PRINCIPALES</strong></h4>
                </div> */}
                <div className="mt-3">
                    <Table
                        noRutaDinamica={true}
                        cargando={false}
                        htmlBtnPag={
                            <button
                                className="btn btn-purple btn-nuevo"
                                type="button"
                                onClick={() => { navigate('/carta-porte/nuevo') }}
                            > Nueva carta porte
                            </button>}
                        headers={[
                            {
                                nombre: 'ID', tipo: 'html', texto: 'NOMBRE_EM', html: (d) => {
                                    return <div>{d.NOMBRE_EM}</div>
                                }
                            },
                            {
                                nombre: 'RUTA', tipo: 'html', texto: 'NOMBRES_CONTACTO_EM', html: (d) => {
                                    return <div>{d.NOMBRES_CONTACTO_EM} {d.APELLIDOS_CONTACTO_EM}</div>
                                }
                            },
                            {
                                nombre: 'FECHA', tipo: 'html', texto: 'NOMBRES_CONTACTO_EM', html: (d) => {
                                    return <div>
                                       {d.CELULAR_CONTACTO_EM}
                                    </div>
                                }
                            },
                            {
                                nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                html: (d) => {
                                    return <div>
                                        <BtnVer
                                            onClick={() => {
                                                navigate(`/carta-porte/detalle`)
                                            }}
                                        />
                                        <BtnEliminar
                                            onClick={() => onClickEliminar(d)}
                                        />

                                        <button
                                            className="btn btn-outline-info btn-sm pl-3 pr-3 pt-1 pb-0"
                                            type="button"> 
                                                <svg style={{ width: 25, height: 25 }} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                                                    
                                                    </svg>
                                        </button>
                                    </div>
                                }
                            }
                        ]}
                        totalField={'NR'}
                        path={'/rt_empresas'}
                        refresh={esRefresh}
                        body={{}}
                        rowsPerPage={20}
                        paginadorTop={true}
                    >
                    </Table>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Default