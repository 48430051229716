import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Accordion, AccordionItem } from "react-light-accordion";
import LoaderSeccion from '../../components/common/loader_secciones';
import Breadcrumb from '../../components/common/breadcrumb';
import "react-light-accordion/demo/css/index.css";
import Tools from '../../js/tools'
import notie from 'notie'
import Select from '../common/select-basic';


export const groupArrayByKey = (xs, key, param) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || { key: x[param], array: [] }).array.push(x);
        return rv;
    }, {});
}

const Default = (props) => {
    const navigate = useNavigate();
    const [esRefresh, setEsRefresh] = useState(false)
    const [envio, setEnvio] = useState({})
    const [historial, setHistorial] = useState([])
    const [catalogos, setCatalogos] = useState({ctgEstatus: []})
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [primeraCarga, setPrimeraCarga] = useState(false)
    const params = useParams();
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(prev => {return !prev});
    };

    const abrirAccordion = () => {
        let accordion_items = document.querySelectorAll('.accordion-item>.title')
        for (let item of accordion_items) {
            item.click();
        }
    }

    function cambiarEstatus() {
        let estatus = document.getElementById('list_est').value
        notie.confirm({
            text: '¿Estás seguro de que deseas cambiar el estatus de este envío?',
            position: 'top',
            submitText: 'Confirmar',
            cancelText: 'Cancelar',
            submitCallback: function () {
                Tools.toolFetch({
                    ruta: 'rt_cambiar_estatus_envio',
                    method: 'POST',
                    body: {id: params.id, estatus},
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning',
                            text: `No fue posible actualizar el estatus del envío.`,
                            time: 3,
                            position: 'top'
                        })
                    }
                    Tools.toolFetch({
                        ruta: 'rt_envio_detalle',
                        method: 'POST',
                        body: { id: params.id },
                    }, (response) => {
                        let datos = response.datos
                        if (response.estatus === 'ERROR') {
                            return notie.alert({
                                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                                // stay: Boolean, // optional, default = false
                                time: 2, // optional, default = 3, minimum = 1,
                                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                            })
                        }
                        console.log(datos)
                        setEnvio(datos.detalleEnvio)
                        setHistorial(datos.historialEnvio)
                        setCatalogos((s) => ({...s, ctgEstatus: datos.ctgEstatus}))
                        toggle()
                    })
                    setPrimeraCarga(true)
                    if (primeraCarga == true && historial && envio && catalogos) {
                        setTimeout(() => {
                            abrirAccordion()
                        }, 750);
                    }
                    notie.alert({
                        type: 'success',
                        text: 'Estatus actualizado correctamente.',
                        time: 3,
                        position: 'top'
                    })
                })
            },
            cancelCallback: function (value) {
                toggle()
            },
        })
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_envio_detalle',
            method: 'POST',
            body: { id: params.id },
        }, (response) => {
            let datos = response.datos
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            console.log(datos)
            setEnvio(datos.detalleEnvio)
            setHistorial(datos.historialEnvio)
            setCatalogos((s) => ({...s, ctgEstatus: datos.ctgEstatus}))
        })
        setPrimeraCarga(true)

    }, [refreshPrimeraCarga])

    useEffect(() => {
        if (primeraCarga == true && historial && envio && catalogos) {
            setTimeout(() => {
                abrirAccordion()
            }, 750);
        }
    }, [catalogos])

    return (
        <div id="divToPrint">
            <Fragment
            >
                <LoaderSeccion status={catalogos}/>
                <div className="col-sm-12 mb-1 mt-3">
                    <Breadcrumb
                        title={"Envío"}
                        parent={"Detalle"}
                    />
                </div>

                <div className='col-md-12 d-flex align-items-end mb-3 ml-0 p-0'>
                    <div className="justify-content-start d-flex">
                        <button className="btn btn-regresar me-1" type="button"
                            onClick={(e) => {
                                navigate('/envios')
                            }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                        {/* <button type="button" className="btn btn-purple ms-1">
                            <i className="fa fa-download"></i> Descargar
                        </button> */}
                    </div>
                    {envio.ESTATUS?.VALOR_EST != 6 ? 
                        <div className="justify-content-end">
                            <button type="button" className="btn btn-purple ms-1" onClick={() => {toggle()}}>
                                <i className="fa fa-truck"></i> Cambiar estatus
                            </button>
                        </div>
                    : ''}
                    
                </div>

                <div
                    className="default-according panel-accordion mt-5"
                    id="accordionclose"
                >
                    <Accordion atomic={false}>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary b-0 m-0"
                            title={<div className="card-title-detalle">
                                {/* <i className="fa fa-usd"></i> */}
                                <h5 className=" font-weight-bold mb-0">
                                    Envío
                                </h5>
                            </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Fecha: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {envio.FECHA_ALTA_TEXT}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Tipo de envío: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <div className="alert alert-primary p-0 m-0" role="alert">
                                                                    {envio.TIPO_ENVIO_TEXT}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Servicio: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {envio.DESCRIPCION_PROD_SERV_SP}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Cliente: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {envio.NOMBRES_CONTACTO_CL} {envio.APELLIDOS_CONTACTO_CL}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Celular: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {envio.CELULAR_CONTACTO_CL || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Correo: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {envio.CORREO_CL || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className='row'>
                                                            <div className="col-md-12">
                                                                <strong>Estatus</strong>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div key={envio.ESTATUS?.FECHA_ALTA_TEXT} className="col-md-3">
                                                                <div className='alert alert-dark p-0 m-0 text-center'>
                                                                    {envio.ESTATUS?.ESTATUS_ENVIO_TEXT}<br />
                                                                    {envio.ESTATUS?.FECHA_ALTA_TEXT}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="info-container">
                                                            <label className="col-form-label text-left font-weight-bold">
                                                                Estatus: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {envio.DESCRIPCION_PROD_SERV_SP}
                                                            </label>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary"
                            title={
                                <div className="card-title-detalle">
                                    {/* <i className="fa fa-usd"></i> */}
                                    <h5 className="ml-3 font-weight-bold mb-0">
                                        Origen - Destino
                                    </h5>
                                </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Dirección de origen: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.OR_DIRECCION || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Dirección destino: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.DES_DIRECCION || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary"
                            title={
                                <div className="card-title-detalle">
                                    {/* <i className="fa fa-usd"></i> */}
                                    <h5 className="ml-3 font-weight-bold mb-0">
                                        Remitente - Destinatario
                                    </h5>
                                </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Remitente: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.REMITENTE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Correo: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.REM_CORREO_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Celular: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.REM_CELULAR_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Teléfono: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.DEST_TELEFONO_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Destinatario: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.DESTINATARIO || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Correo: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.DEST_CORREO_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Celular: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.DEST_CELULAR_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                        Teléfono: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {envio.DEST_TELEFONO_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary"
                            title={
                                <div className="card-title-detalle">
                                    {/* <i className="fa fa-usd"></i> */}
                                    <h5 className="ml-3 font-weight-bold mb-0">
                                        Información de carga
                                    </h5>
                                </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Largo (cm): &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        {`${envio.LARGO_SE} ` || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Ancho (cm): &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        {envio.ANCHO_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Alto (cm): &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        {envio.ALTO_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Tipo peso: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        {envio.TIPO_PESO_TEXT}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Peso real (kg):
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        {envio.PESO_REAL_SE || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        Peso vol (kg):
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        {envio.PESO_VOLUMETRICO_SE}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary"
                            title={
                                <div className="card-title-detalle">
                                    {/* <i className="fa fa-usd"></i> */}
                                    <h5 className="ml-3 font-weight-bold mb-0">
                                        Totales
                                    </h5>
                                </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        SUBTOTAL: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        $ {envio.SUBTOTAL_TEXT}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        IVA:
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        $ {envio.IVA_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold" style={{ width: 100 }}>
                                                                        TOTAL:
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        $ {envio.TOTAL_TEXT}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary"
                            title={
                                <div className="card-title-detalle">
                                    {/* <i className="fa fa-usd"></i> */}
                                    <h5 className="ml-3 font-weight-bold mb-0">
                                        Historial
                                    </h5>
                                </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    <div className="col-md-8">
                                                        <div class="comments">
                                                            {historial.map((v, i) => {
                                                                return <div className='actualizacion'>
                                                                        <h6>{v.FECHA_TEXTO}</h6>
                                                                        <p>{v.HORA_TEXTO} | {v.TEXTO_ESTATUS}.</p>
                                                                        <p className='lugar'>{v.NOMBRE_CD} | {v.NOMBRE_EDO}</p>
                                                                    </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                    <div style={{ height: 50 }}></div>
                </div>
                <Modal isOpen={modal} toggle={toggle} centered={true} backdrop={'static'}>
                    <ModalHeader toggle={toggle}>Cambiar estatus</ModalHeader>
                    <ModalBody>
                        Para continuar, seleccione el estatus del envío.
                        <br></br><br></br>
                        <Select
                            className="form-control form-control-md border border-dark"
                            data-label="Entrada"
                            placeholder="Seleccionar estatus"
                            id="list_est"
                            name="list_est"
                            tipoValue={1}
                            datos={catalogos.ctgEstatus}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-purple ms-1 align-items-center rounded-pill" type="button" onClick={() => {cambiarEstatus()}}>Cambiar estatus</button>
                    </ModalFooter>
            </Modal>
            </Fragment>
        </div>
    )
}

export default Default;