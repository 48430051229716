import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Tools from '../../js/tools'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Clipboard } from 'react-feather';
import { Accordion, AccordionItem } from "react-light-accordion";
import LoaderSeccion from '../../components/common/loader_secciones';
import "react-light-accordion/demo/css/index.css";

export const groupArrayByKey = (xs, key, param) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || { key: x[param], array: [] }).array.push(x);
        return rv;
    }, {});
}

const Default = ({ esEditar }) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [solicitud, setSolicitud] = useState({})
    const [partidas, setPartidas] = useState([])
    const [comprobantes, setComprobantes] = useState([])
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [primeraCarga, setPrimeraCarga] = useState(false)
    const [modal, setModal] = useState(false);
    const [unmountOnClose, setUnmountOnClose] = useState(false);
    const [rechazado, setRechazado] = useState(false);
    const [aceptado, setAceptado] = useState(false);
    const [idModalAbierto, setIdModalAbierto] = useState('');
    const toggle = () => setModal(!modal);
    const navigate = useNavigate();
    const params = useParams();

    const abrirAccordion = () => {
        let accordion_items = document.querySelectorAll('.accordion-item .title')
        for (let item of accordion_items) {
            item.click();
        }
    }

    const changeUnmountOnClose = (e) => {
        let { value } = e.target;
        setUnmountOnClose(JSON.parse(value));
    };

    function aceptarComprobante(id) {

        notie.confirm({
            text: '¿Estás seguro de que deseas aceptar este comprobante?',
            cancelText: 'Cancelar',
            submitText: 'Confirmar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_aceptar_comprobante',
                    method: 'POST',
                    body: {id: params.id, ID_PAS: id},
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'error',
                            text: `No fue posible aceptar este comprobante, intentalo más tarde.`,
                            time: 2,
                            position: 'top'
                        })
                    }
                    comprobantes.map((element, index) => {
                        if(element.ID_PAS == id){
                            comprobantes[index].FECHA_ACEPTACION_PAS = response.datos.FECHA_ACEPTACION_PAS
                            comprobantes[index].ESTATUS_PAGO_PAS = 2
                            setAceptado(true)
                        }
                    })
                    notie.alert({
                        type: 'success',
                        text: `El comprobante fue aceptado.`,
                        time: 2,
                        position: 'top'
                    })
                })
            }
        })
    }

    function rechazarComprobante(id) {
        notie.confirm({
            text: '¿Estás seguro de que deseas rechazar este comprobante?',
            cancelText: 'Cancelar',
            submitText: 'Confirmar',
            submitCallback: function (value) {
                toggle()
                Tools.toolFetch({
                    ruta: 'rt_rechazar_comprobante',
                    method: 'POST',
                    body: { id: params.id, motivo: document.getElementById('motivo').value, ID_PAS: id},
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'error',
                            text: `No fue posible rechazar este comprobante, intentalo más tarde.`,
                            time: 2,
                            position: 'top'
                        })
                    }
                    comprobantes.map((element, index) => {
                        if(element.ID_PAS == id){
                            comprobantes[index].FECHA_MOTIVO_PAS = response.datos.FECHA_MOTIVO_PAS
                            comprobantes[index].MOTIVO_PAS = response.datos.MOTIVO_PAS
                            comprobantes[index].ESTATUS_PAGO_PAS = 3
                            setRechazado(true)
                        }
                    })
                    document.getElementById('motivo').value = ''
                    notie.alert({
                        type: 'success',
                        text: `El comprobante fue rechazado.`,
                        time: 2,
                        position: 'top'
                    })
                })
            }
        })
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_solicitudes_detalle',
            method: 'POST',
            body: { id: params.id },
        }, (response) => {
            let datos = response.datos
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            setComprobantes(datos.comprobantes)
            setPartidas(datos.partidas)
            setSolicitud(datos.solicitud)
        })

        setPrimeraCarga(true)
    }, [refreshPrimeraCarga])

    useEffect(() => {
        if (primeraCarga == true && solicitud && partidas && comprobantes) {
            setTimeout(() => {
                abrirAccordion()
            }, 750);
        }
    }, [solicitud])

    return (
        // <div id="divToPrint">
            <Fragment>
                <LoaderSeccion status={solicitud}/>
                <div className="col-sm-12 mb-1 mt-3">
                    <Breadcrumb icon={Clipboard} title={'Solicitudes'} parent={['solicitudes', esEditar?params.id:'']} />
                </div>

                <div className='col-md-12 d-flex align-items-end justify-content-start mb-3 ml-0 p-0'>
                    <button className="btn btn-regresar me-1" type="button"
                    onClick={(e) => {
                        navigate('/solicitudes')
                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                    <button type="button" className="btn btn-purple ms-1">
                        <i className="fa fa-download"></i> Descargar
                    </button>     
                                           
                </div>

                <div
                    className="default-according panel-accordion  mt-5"
                    id="accordionclose"
                >
                    <Accordion atomic={false}>
                        <AccordionItem eventKey="0" className="card-header bg-primary b-0 m-0" 
                        title={
                            <div className="card-title-detalle"> 
                                {/* <i className="fa fa-usd"></i> */}
                                <h5 className=" fw-bold mb-0">Cliente</h5>
                            </div>}>
                            <div className="collapse show" id="collapseicon2" aria-labelledby="collapseicon2" data-parent="#accordionoc">
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    {/* <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Nombre completo: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <div
                                                                    className={`alert alert-light p-2 m-0 rounded `}
                                                                    role="alert"
                                                                >
                                                                    {solicitud.NOMBRES_CONTACTO_CL?
                                                                    `${solicitud.NOMBRES_CONTACTO_CL} ${solicitud.APELLIDOS_CONTACTO_CL}`
                                                                    :'-'}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Nombre completo: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {solicitud.NOMBRES_CONTACTO_CL ?
                                                                    `${solicitud.NOMBRES_CONTACTO_CL} ${solicitud.APELLIDOS_CONTACTO_CL}`
                                                                    : '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Celular: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {solicitud.CELULAR_CONTACTO_CL || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Correo: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {solicitud.CORREO_CL || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Fecha: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {solicitud.FECHA_ALTA_TEXT || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary"
                            title={
                                <div className="card-title-detalle">
                                    {/* <i className="fa fa-usd"></i> */}
                                    <h5 className="ml-3 fw-bold mb-0">
                                        Datos de la solicitud
                                    </h5>
                                </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <form className="theme-form">
                                                <div className="row m-auto">
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left fw-bold">
                                                                        Fecha registro: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {solicitud.FECHA_PAGO_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left fw-bold">
                                                                        Estatus solicitud: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        <div className="alert alert-light m-0 p-1" role="alert">
                                                                            {solicitud.ESTATUS_SOLICITUD_TEXT || '-'}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left fw-bold">
                                                                        Estatus pago: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        <div className="alert alert-light m-0 p-1" role="alert">
                                                                            {solicitud.ESTATUS_PAGO_TEXT || '-'}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left fw-bold">
                                                                        Estatus factura: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        <div className="alert alert-light m-0 p-1" role="alert">
                                                                            {solicitud.ESTATUS_CFDI_TEXT || '-'}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left fw-bold" style={{ width: 100 }}>
                                                                        Subtotal: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        ${solicitud.SUBTOTAL_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left fw-bold" style={{ width: 100 }}>
                                                                        Descuento: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        ${solicitud.DESCUENTO_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left fw-bold" style={{ width: 100 }}>
                                                                        Iva: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        ${solicitud.IVA_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left fw-bold" style={{ width: 100 }}>
                                                                        Total: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-end" style={{ width: 100 }}>
                                                                        ${solicitud.TOTAL_TEXT || '-'}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Tipo de pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <div
                                                                    className={`alert alert-light p-2 m-0 rounded `}
                                                                    role="alert"
                                                                >
                                                                    {registro.informacion.PASARELA_PAGO || '-'}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Id. de transacción: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                {registro.informacion.TRANSACCION || '-'}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Código de descuento: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Importe de descuento: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Monto: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Estatus del pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <div
                                                                    className={`alert alert-light p-2 m-0 rounded`}
                                                                    role="alert"
                                                                >
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Fecha y hora del pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Concepto del pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info-container">
                                                            <label className="col-form-label text-left fw-bold">
                                                                Comprobante de pago: &nbsp;
                                                            </label>
                                                            <label className="col-form-label text-left">
                                                                <button type="button" className="btn btn-primary">
                                                                    <i className="fa fa-download"></i> Descargar
                                                                </button>
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem
                            eventKey="0"
                            className="card-header bg-primary b-0 m-0"
                            title={<div className="card-title-detalle">
                                <h5 className=" fw-bold mb-0">Partidas</h5>
                            </div>
                            }
                        >
                            <div
                                className="collapse show"
                                id="collapseicon2"
                                aria-labelledby="collapseicon2"
                                data-parent="#accordionoc"
                            >
                                <div className="card container-fluid">
                                    <br />
                                    <div className="row m-auto w-100">
                                        <div className="col-md-12 p-0">
                                            <div className="row m-auto w-100">
                                                <div className="col-md-12 p-0">
                                                    <form className="theme-form">
                                                        <div className="info-container">
                                                            <div className="list-group">
                                                                {partidas.map((v, i) =>
                                                                    <span key={i} className="list-group-item list-group-item-action flex-column align-items-start">
                                                                        <div className="d-flex w-100 justify-content-between">
                                                                            <h5 className="mb-1">{v.DESCRIPCION_PROD_SERV_SP}</h5>
                                                                            {/* <a href='#' className="link-primary" onClick={(e) => verDatelle(e,v)}>Ver detalle</a> */}
                                                                        </div>
                                                                        <div className="d-flex w-100 justify-content-between">
                                                                            <h6 className="mb-1">Cant. {v.CANTIDAD_SP}</h6>
                                                                            <h5 className="mb-1">${v.TOTAL_TEXT}</h5>
                                                                        </div>
                                                                        {v.TIPO_SERVICIO_SP == 1 ?
                                                                            <div className='row'>
                                                                                <div className="col-md-6">
                                                                                    <p className="fw-bold mb-0 pb-0" style={{ fontWeight: 600 }}>Origen</p>
                                                                                    <p className="font-weight-normal mb-0 pb-0">
                                                                                        {v.OR_DIRECCION}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <p className="fw-bold mb-0 pb-0" style={{ fontWeight: 600 }}>Destino</p>
                                                                                    <p className="font-weight-normal mb-0 pb-0">{v.DES_DIRECCION}</p>
                                                                                </div>
                                                                            </div> : ''}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </AccordionItem>
                        <Fragment>
                            {comprobantes?.length > 0 ?   
                                <AccordionItem eventKey="0" className="card-header bg-primary b-0 m-0" 
                                    title={<div className="card-title-detalle">
                                        <h5 className=" fw-bold mb-0">
                                            Comprobante de pago
                                        </h5>
                                    </div>}>
                                    <div className="collapse show" id="collapseicon3" aria-labelledby="collapseicon3" data-parent="#accordionoc">
                                        <div className="card container-fluid">
                                            {comprobantes.map((element) =>
                                                <>
                                                    <div className="row m-auto w-100">
                                                        <div className="col-md-12 p-0">
                                                            <form className="theme-form">
                                                                <div className="row m-auto">
                                                                    <div className="col-md-6">
                                                                        <div className="info-container d-flex flex-column">
                                                                            <label className="col-form-label text-left fw-bold">
                                                                                Comprobante de pago: &nbsp;
                                                                            </label>
                                                                            <br/>
                                                                            <img className='' style={{margin: '-1.5% auto 0px', maxHeight: '850px', maxWidth: '100%'}} src={'http://localhost:3001/comprobantes/'+element.COMPROBANTE_PAS}/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="info-container">
                                                                            <label className="col-form-label text-left fw-bold">
                                                                                Concepto: &nbsp;
                                                                            </label>
                                                                            <br/>
                                                                            <label className="col-form-label text-left">
                                                                                {element.FOLIO_TRANSFERENCIA_PAS}
                                                                            </label>
                                                                        </div>
                                                                        {element.ESTATUS_PAGO_PAS == 3  || rechazado == true ? 
                                                                            <>
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left fw-bold">
                                                                                        Fecha de rechazo: &nbsp;
                                                                                    </label>
                                                                                    <br/>
                                                                                    <label className="col-form-label text-left">
                                                                                        {element.FECHA_MOTIVO_PAS}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left fw-bold">
                                                                                        Motivo del rechazo: &nbsp;
                                                                                    </label>
                                                                                    <br/>
                                                                                    <label className="col-form-label text-left">
                                                                                        {element.MOTIVO_PAS}
                                                                                    </label>
                                                                                </div>
                                                                            </>
                                                                        : ''}
                                                                        {element.ESTATUS_PAGO_PAS == 2  || aceptado == true ? 
                                                                            <>
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left fw-bold">
                                                                                        Fecha de aceptación: &nbsp;
                                                                                    </label>
                                                                                    <br/>
                                                                                    <label className="col-form-label text-left">
                                                                                        {element.FECHA_ACEPTACION_PAS}
                                                                                    </label>
                                                                                </div>
                                                                            </>
                                                                        : ''}
                                                                        {element.ESTATUS_PAGO_PAS == 1 ?
                                                                        <div className="info-container d-flex mt-3 mt-md-5">
                                                                            <button class="btn btn-green d-flex align-items-center rounded-pill me-3" type="button" onClick={() => {aceptarComprobante(element.ID_PAS)}}> Aceptar</button>
                                                                            <button class="btn btn-red d-flex align-items-center rounded-pill" type="button" onClick={() => {
                                                                                setIdModalAbierto(`${element.ID_PAS}`);
                                                                                toggle()}}> Rechazar</button>
                                                                        </div>
                                                                        : ''}
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <hr></hr>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </AccordionItem>
                            : ''}
                        </Fragment>
                    </Accordion>
                    <div style={{ height: 50 }}></div>
                </div>
                <Modal isOpen={modal} toggle={toggle} centered={true} backdrop={'static'} unmountOnClose={unmountOnClose} style={{width: '25%', fontSize: '16px'}}>
                    <ModalHeader toggle={toggle}>Rechazar comprobante</ModalHeader>

                    <ModalBody>
                        Para continuar, ingrese el motivo por el cual el comprobante está siendo rechazado.
                        <br></br><br></br>
                        <Input
                            type="textarea"
                            id='motivo'
                            style={{width: '100%', backgroundColor: 'white', borderColor: 'white', fontSize: '15px'}} 
                            placeholder='Motivo del rechazo'
                            rows={4}
                        />
                    </ModalBody>
                    
                    <ModalFooter>
                        <button class="btn btn-back d-flex align-items-center rounded-pill" type="button" onClick={() => toggle()}> Cancelar</button>
                        <button class="btn btn-red d-flex align-items-center rounded-pill" type="button" onClick={() => {rechazarComprobante(idModalAbierto)}}> Rechazar</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        // </div>
        
    )
}

export default Default;