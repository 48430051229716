import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../../components/common/breadcrumb';
import { Send } from 'react-feather';
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from "reactstrap";

export const groupArrayByKey = (xs, key, param) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || { key: x[param], array: [] }).array.push(x);
        return rv;
    }, {});
}

const Default = (props) => {
    const [esRefreshLoc,esRefresh, setEsRefresh] = useState(false)
    const [envio, setEnvio] = useState({})
    const [solicitud, setSolicitud] = useState({})
    const [partidas, setPartidas] = useState([])
    const navigate = useNavigate();
    const params = useParams();

    const abrirAccordion = () => {
        let accordion_items = document.querySelectorAll('.accordion-item>.title')
        for (let item of accordion_items) {
            item.click();
        }
    }

    const verDatelle = (e, item) => {
        e.preventDefault()
        console.log(item)
    }
    
    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se eliminara <strong>${d.NOMBRE_EM}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_empresas_eliminar',
                    method: 'POST',
                    body: { id: d.ID_EM },
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = falses
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    setEsRefresh((s) => !s)
                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_envio_detalle',
            method: 'POST',
            body: { id: params.id },
        }, (response) => {
            let datos = response.datos
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            console.log(datos)
            setEnvio(datos.detalleEnvio)
        })

        abrirAccordion()
    }, [])

    return (
        <div id="divToPrint">
            <Fragment
            >
                <div className='col-sm-12 mb-1 mt-3'>
                    <Breadcrumb title={"Instaladores"} parent={"Detalle"} />                
                </div>
                <div className='col-md-12 d-flex align-items-end justify-content-start mb-3 ml-0 p-0'>
                    <button className="btn btn-regresar me-1" type="button"
                    onClick={(e) => {
                        navigate('/instaladores')
                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                            
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5'>
                    <div className="card">
                        <div className="card-body"> 
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <form className="theme-form">
                                        <div className="row m-auto">
                                            <div className='col-md-12'>
                                                {/* <h4>21/01/2025 4:42 PM</h4> */}
                                                <ul className='list-summary-detail'>
                                                    {/* <li className='marked'>Token: <span>eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c</span></li>
                                                    <li className='second'>Fecha: <span>21/01/2025 4:42 PM</span></li> */}
                                                    <li className='marked'>Versión: <span>1.0.0</span></li>
                                                    <li className='second'>Fecha: <span>12/03/2025</span></li>
                                                    <li>Descargar: 
                                                        <div className='mt-3'>
                                                            <button type="button" className="btn btn-purple ms-1">
                                                                <i className="fa fa-download"></i> Descargar .exe
                                                            </button>    
                                                            <button type="button" className="btn btn-purple ms-1">
                                                                <i className="fa fa-download"></i> Descargar .zip
                                                            </button>    
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>    
                    </div>                             
                </div>

            </Fragment>
        </div>
    )
}

export default Default;