import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-light-accordion/demo/css/index.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-light-accordion/demo/css/index.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// import Inicio from '../components_gpack/admin/inicio';
import Clientes from '../components_gpack/clientes/clientes';
import ClientesNuevo from '../components_gpack/clientes/clientes_registro';
import ClientesDetalle from '../components_gpack/clientes/clientes_perfil';

// servicios
import ProductosServicios from '../components_gpack/servicios/servicios';
import ProductosServiciosForm from '../components_gpack/servicios/servicios_form';
import ProductosServiciosParametros from '../components_gpack/servicios/precios_parametros';

// Guías prepagadas
import GuiasPrepagadas from '../components_gpack/guias-prepagadas/guias-prepagadas';
import GuiasPrepagadasForm from '../components_gpack/guias-prepagadas/guias-prepagadas_form';
import GuiasPrepagadasPromociones from '../components_gpack/guias-prepagadas/guias-prepagadas_promociones';
import GuiasPrepagadasParametros from '../components_gpack/guias-prepagadas/guias-prepagadas_parametros';

// SimpliRoute
import SimpliRouteVehiculos from '../components_gpack/simpliroute/vehiculos/vehiculos';
import SimpliRouteVehiculosForm from '../components_gpack/simpliroute/vehiculos/vehiculos_form';
import SimpliRouteConductores from '../components_gpack/simpliroute/conductores/conductores';
import SimpliRouteConductoresForm from '../components_gpack/simpliroute/conductores/conductores_form';

// sucursales
import Sucursales from '../components_gpack/sucursales/sucursales';
import SucursalesNuevo from '../components_gpack/sucursales/sucursales_nuevo';
import SucursalesDetalle from '../components_gpack/sucursales/sucursales_detalle';

// Usuarios
import UsuariosNuevo from '../components_gpack/usuarios/usuarios_nuevo';
import Usuarios from '../components_gpack/usuarios/usuarios';
import UsuariosDetalle from '../components_gpack/usuarios/usuarios_detalle';

// Usuarios TPV
import UsuariosNuevoTpv from '../components_gpack/usuarios_tpv/usuarios_nuevo';
import UsuariosTpv from '../components_gpack/usuarios_tpv/usuarios';
import UsuariosDetalleTpv from '../components_gpack/usuarios_tpv/usuarios_detalle';

// Cobertura
import DefinirCobertura from '../components_gpack/cobertura/cobertura';
import DefinirZonas from '../components_gpack/cobertura/zonas';

// Envío
import EnviosDetalle from '../components_gpack/_envios/envios_detalle';
import EnviosNuevo from '../components_gpack/_envios/envios';
import Envios from '../components_gpack/_envios/envios_realizados';
// import Envios from '../components_gpack/envios_paquetes/envios';

// Solicitudes
import Solicitudes from '../components_gpack/_envios/solicitudes';
import SolicitudesDetalle from '../components_gpack/_envios/solicitudes_detalle';

//Reportes de caja
import AperturaCaja from '../components_gpack/reportes_caja/apertura_caja';
import CorteCaja from '../components_gpack/reportes_caja/corte_caja';

// Empresa
import Empresa from '../components_gpack/empresa/empresa';
import EmpresaForm from '../components_gpack/empresa/empresa_form';

// Datos Fiscales
import DatosFiscales from '../components_gpack/empresa/empresa_df';
import DatosFiscalesForm from '../components_gpack/empresa/empresa_df_form';
import DatosPerfil from '../components_gpack/empresa/empresa_perfil';

// Perfil
import UsuariosPerfil from '../components_gpack/usuarios/usuarios_perfil';

//EnviosPaquete
import EnviosPaquete from '../components_gpack/envios_paquetes/envios_paquetes';
import EnviosRastreo from '../components_gpack/envios_rastreo/EnviosRastreo';

//EnviosPaquete
import Plantillas from '../components_gpack/plantillas/plantillas';
import PlantillasForm from '../components_gpack/plantillas/plantillas_form';
import PlantillasFormDetalle from '../components_gpack/plantillas/plantillas_form_detalle';

//Rutas
import Rutas from '../components_gpack/rutas/rutas';
import Rutasdetalle from '../components_gpack/rutas/rutas_detalle';

//Carta Porte
import CartaPorte from '../components_gpack/carta_porte/carta_porte';
import CartaPorteDetalle from '../components_gpack/carta_porte/carta_porte_detalle';
import CartaPorteNuevo from '../components_gpack/carta_porte/carta_porte_form';


//Instaladores
import Instaladores from '../components_gpack/instaladores/instaladores';
import InstaldoresDetallle from '../components_gpack/instaladores/instaladores_detalle';
import InstaldoresNuevo from '../components_gpack/instaladores/instaladores_form';


//Facturas 
import Facturas from '../components_gpack/facturacion/facturas';
import FacturasNuevo from '../components_gpack/facturacion/facturas_form';


export const routes = [
    // { path: `${process.env.PUBLIC_URL}/`, Component: <Empresa esPrincipal={true}/> },
    { path: `${process.env.PUBLIC_URL}/`, Component: <Clientes/> },
    // { path: `${process.env.PUBLIC_URL}/estadisticas`, Component: <Inicio /> },
    // Clientes
    { path: `${process.env.PUBLIC_URL}/clientes`, Component: <Clientes /> },
    { path: `${process.env.PUBLIC_URL}/clientes/nuevo`, Component: <ClientesNuevo /> },
    { path: `${process.env.PUBLIC_URL}/clientes/:id`, Component: <ClientesDetalle /> },

    // Productos servicios
    { path: `${process.env.PUBLIC_URL}/servicios`, Component: <ProductosServicios /> },
    { path: `${process.env.PUBLIC_URL}/servicios/nuevo`, Component: <ProductosServiciosForm /> },
    { path: `${process.env.PUBLIC_URL}/servicios/parametros`, Component: <ProductosServiciosParametros /> },
    { path: `${process.env.PUBLIC_URL}/servicios/:id`, Component: <ProductosServiciosForm esEditar={true} /> },
    // Guías prepagadas
    { path: `${process.env.PUBLIC_URL}/guias-prepagadas`, Component: <GuiasPrepagadas /> },
    { path: `${process.env.PUBLIC_URL}/guias-prepagadas/nuevo`, Component: <GuiasPrepagadasForm /> },
    { path: `${process.env.PUBLIC_URL}/guias-prepagadas/:id`, Component: <GuiasPrepagadasForm esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/guias-prepagadas/promociones`, Component: <GuiasPrepagadasPromociones /> },
    { path: `${process.env.PUBLIC_URL}/guias-prepagadas/parametros`, Component: <GuiasPrepagadasParametros /> },
    // SimpliRoute
    { path: `${process.env.PUBLIC_URL}/vehiculos`, Component: <SimpliRouteVehiculos /> },
    { path: `${process.env.PUBLIC_URL}/vehiculos/nuevo`, Component: <SimpliRouteVehiculosForm /> },
    { path: `${process.env.PUBLIC_URL}/vehiculos/:id`, Component: <SimpliRouteVehiculosForm esEditar={true}/> },
    { path: `${process.env.PUBLIC_URL}/conductores`, Component: <SimpliRouteConductores /> },
    { path: `${process.env.PUBLIC_URL}/conductores/nuevo`, Component: <SimpliRouteConductoresForm /> },
    { path: `${process.env.PUBLIC_URL}/conductores/:id`, Component: <SimpliRouteConductoresForm esEditar={true}/> },
    // Sucursales
    { path: `${process.env.PUBLIC_URL}/sucursales`, Component: <Sucursales /> },
    { path: `${process.env.PUBLIC_URL}/sucursales/nuevo`, Component: <SucursalesNuevo /> },
    { path: `${process.env.PUBLIC_URL}/sucursales/:sucursal`, Component: <SucursalesDetalle /> },
    // Usuarios
    { path: `${process.env.PUBLIC_URL}/usuarios-cms/nuevo`, Component: <UsuariosNuevo /> },
    { path: `${process.env.PUBLIC_URL}/usuarios-cms`, Component: <Usuarios /> },
    { path: `${process.env.PUBLIC_URL}/usuarios-cms/:id`, Component: <UsuariosDetalle /> },
    // Usuarios TPV
    { path: `${process.env.PUBLIC_URL}/usuarios-tpv/nuevo`, Component: <UsuariosNuevoTpv /> },
    { path: `${process.env.PUBLIC_URL}/usuarios-tpv`, Component: <UsuariosTpv /> },
    { path: `${process.env.PUBLIC_URL}/usuarios-tpv/detalle/:id`, Component: <UsuariosDetalleTpv /> },
    // Perfil
    { path: `${process.env.PUBLIC_URL}/perfil`, Component: <UsuariosPerfil /> },
    // Cobertura
    { path: `${process.env.PUBLIC_URL}/definir-cobertura`, Component: <DefinirCobertura /> },
    { path: `${process.env.PUBLIC_URL}/definir-zonas`, Component: <DefinirZonas /> },
    // Solicitudes
    { path: `${process.env.PUBLIC_URL}/solicitudes`, Component: <Solicitudes /> },
    { path: `${process.env.PUBLIC_URL}/solicitudes/:id`, Component: <SolicitudesDetalle esEditar={true}/> },
    // Reportes de caja
    { path: `${process.env.PUBLIC_URL}/apertura-caja`, Component: <AperturaCaja /> },
    { path: `${process.env.PUBLIC_URL}/corte-caja`, Component: <CorteCaja /> },
    // Envío
    { path: `${process.env.PUBLIC_URL}/envios`, Component: <Envios /> },
    { path: `${process.env.PUBLIC_URL}/envios/nuevo`, Component: <EnviosNuevo /> },
    { path: `${process.env.PUBLIC_URL}/envios/:id`, Component: <EnviosDetalle /> },
    // { path: `${process.env.PUBLIC_URL}/envios`, Component: <Envios />},
    // Empresas
    { path: `${process.env.PUBLIC_URL}/empresa`, Component: <Empresa esPrincipal={true}/> },
    { path: `${process.env.PUBLIC_URL}/empresa/nueva`, Component: <EmpresaForm esPrincipal={true}/> },
    { path: `${process.env.PUBLIC_URL}/empresa/editar`, Component: <EmpresaForm esPrincipal={true} esEditar={true}/> },
    // { path: `${process.env.PUBLIC_URL}/empresa/:id`, Component: <EmpresaNueva esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/:empresa`, Component: <DatosPerfil esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/datos-fiscales`, Component: <DatosFiscales esPrincipal={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/datos-fiscales/nuevo`, Component: <DatosFiscalesForm esPrincipal={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/datos-fiscales/:aliasDf`, Component: <DatosFiscalesForm esPrincipal={true} esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/:empresa/datos-fiscales`, Component: <DatosPerfil esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/:empresa/datos-fiscales/:aliasDf`, Component: <DatosPerfil esEditar={true} /> },
    //envios_paquetes
    { path: `${process.env.PUBLIC_URL}/envios_paquetes`, Component: <EnviosPaquete/> },
    { path: `${process.env.PUBLIC_URL}/envios_rastreo`, Component: <EnviosRastreo /> },
    
    // Productos servicios
    { path: `${process.env.PUBLIC_URL}/plantillas`, Component: <Plantillas esPrincipal={true}/> },
    { path: `${process.env.PUBLIC_URL}/plantillas/nuevo`, Component: <PlantillasForm esPrincipal={true}/> },
    { path: `${process.env.PUBLIC_URL}/plantillas/:plantilla`, Component: <PlantillasFormDetalle esPrincipal={true} esEditar={true}/> },

    //Rutas 
    { path: `${process.env.PUBLIC_URL}/rutas`, Component: <Rutas/> },
    { path: `${process.env.PUBLIC_URL}/rutas/:ruta`, Component: <Rutasdetalle /> },

    //Carta porte
    { path: `${process.env.PUBLIC_URL}/carta-porte`, Component: <CartaPorte/> },
    { path: `${process.env.PUBLIC_URL}/carta-porte/detalle`, Component: <CartaPorteDetalle/> },
    { path: `${process.env.PUBLIC_URL}/carta-porte/nuevo`, Component: <CartaPorteNuevo/> },

    //Instaladores
    { path: `${process.env.PUBLIC_URL}/instaladores`, Component: <Instaladores/> },
    { path: `${process.env.PUBLIC_URL}/instaladores/detalle`, Component: <InstaldoresDetallle/> },
    { path: `${process.env.PUBLIC_URL}/instaladores/nuevo`, Component: <InstaldoresNuevo/> },

    //Facturacion
    { path: `${process.env.PUBLIC_URL}/facturas`, Component: <Facturas/> },
    { path: `${process.env.PUBLIC_URL}/facturas/nuevo`, Component: <FacturasNuevo/> },

]
