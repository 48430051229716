import React from "react"

const FilterRemove = ({
    style,
    onClick,
    className
}) => {
    return (
    <span className={`icon-stack ${className}`} style={style || {}} onClick={onClick}>
        <i className="icon-filter icon-stack-base"></i>
        <span className="deleted-float-filter">
            Limpiar
            <i className="fa fa-repeat"></i>
        </span>
        
    </span>)
}

export {
    FilterRemove
}