import React, { Fragment, useEffect, useState } from 'react';
import Tools from '../../js/tools'
import notie from 'notie'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../../components/common/breadcrumb';
import Select from '../common/select-basic'
import SelectFetch from '../common/select-fetch'
// import '../../assets/css/bootstrap.min.css'

const Default = ({
    esPrincipal,
    esEditar
}) => {
    const navigate = useNavigate()
    const params = useParams();
    const [form, setForm] = useState({});
    const [folio, setFolio] = useState('');
    const [catalogos, setCatalogos] = useState({});
    const [datosFiscales, setDatosFiscales] = useState({});
    const [datosFiscalesEm, setDatosFiscalesEm] = useState({});
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(prev => {return !prev});
    };

    function onSelectFolio(folio){
        setFolio(folio)
        document.getElementById('inputRazonSocial').value = ''
        document.getElementById('inputRFC').value = ''
        document.getElementById('inputCodigoPostal').value = ''
        document.getElementById('inputCorreo').value = ''
        document.getElementById('inputUsoCFDI').value = ''
        document.getElementById('inputRegimenFiscal').value = ''
        Tools.toolFetch({
            ruta: 'rt_cargar_DF',
            method: 'POST',
            body: { folio }
        }, (response) => {
            setCatalogos({inputRegimenFiscal: response.ctgRegimen, inputUsoCFDI: response.ctgUsoCFDI})
            setDatosFiscalesEm({datosFiscalesEm: response.datosFiscalesEm})
            setDatosFiscales({listDatosFiscales: response.datosFiscales})
            document.getElementById('oculto-header-0').classList.remove("d-none")
            document.getElementById('oculto-0').classList.remove("d-none")
        })
    }

    function onSelectDatosFiscales(ID_DF) {
        document.getElementById('oculto-1').classList.remove("d-none")
        document.getElementById('oculto-2').classList.remove("d-none")
        document.getElementById('oculto-3').classList.remove("d-none")
        document.getElementById('oculto-4').classList.remove("d-none")
        document.getElementById('oculto-5').classList.remove("d-none")
        document.getElementById('oculto-6').classList.remove("d-none")
        document.getElementById('oculto-7').classList.remove("d-none")
    
        if (ID_DF == 0) {
            // Vaciar campos si se selecciona "Seleccionar datos fiscales"
            document.getElementById('inputRazonSocial').value = ''
            document.getElementById('inputRFC').value = ''
            document.getElementById('inputCodigoPostal').value = ''
            document.getElementById('inputCorreo').value = ''
            document.getElementById('inputUsoCFDI').value = ''
            document.getElementById('inputRegimenFiscal').value = ''
        } else {
            // Si el valor es distinto de 0, cargar los datos de la base de datos
            Tools.toolFetch({
                ruta: 'rt_select_DF',
                method: 'POST',
                body: { ID_DF }
            }, (response) => {
                setDatosFiscales((s)=>({...s, datosFiscales: response.datosFiscales}))
                let DF = response.datosFiscales;
                document.getElementById('inputRazonSocial').value = DF.RAZON_SOCIAL
                document.getElementById('inputRFC').value = DF.RFC
                document.getElementById('inputCodigoPostal').value = DF.CP
                document.getElementById('inputCorreo').value = DF.CORREO
                document.getElementById('inputUsoCFDI').value = DF.CLAVE_USO_CFDI
                document.getElementById('inputRegimenFiscal').value = DF.CLAVE_REG_FISCAL
            })
        }
    }

    function generarFactura() {
        // const modal1 = new bootstrap.Modal(document.getElementById('modal'));
        let form = Tools.getForm('datos_fiscales', { ignore: ['listDatosFiscalesRem'] });
        form.data.folio = folio
    
        if (form.data.folio == '') {
            return notie.alert({
                type: 'warning',
                text: `Es necesario ingresar todos los datos para continuar.`,
                time: 3,
                position: 'top'
            })
        }
    
        //VALIDACIONES
        if (form.formIsEmpty) {
            // modal1.hide();
            return notie.alert({
                type: 'warning',
                text: `Es necesario ingresar todos los datos para continuar.`,
                time: 3,
                position: 'top'
            })
        }
    
        if (!form.data.inputCorreo.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            return notie.alert({
                type: 'warning',
                text: `Por favor ingrese un correo válido.`,
                time: 3,
                position: 'top'
            })
        }
    
        if (form.data.inputCodigoPostal.length < 5) {
            return notie.alert({
                type: 'warning',
                text: `Por favor ingrese un código postal válido.`,
                time: 3,
                position: 'top'
            })
        }
        // modal1.show();
        toggle()
    
        Tools.toolFetch({
            ruta: 'rt_generar_factura',
            method: 'POST',
            body: { ...form.data, timbrar: 0}
        }, (response) => {
            if (response.estado == 1) {
                notie.alert({
                    type: 'success',
                    text: `Factura generada correctamente.`,
                    time: 3,
                    position: 'top'
                })
            } else if (response.estado == 0) {
                if(response?.timbrar == 0){
                    notie.confirm({
                        text: `La fecha límite para realizar esta factura era el "${response.fecha}" ¿Estás seguro de que deseas realizar esta factura?`,
                        cancelText: 'Cancelar',
                        submitText: 'Confirmar',
                        submitCallback: function (value) {
                            Tools.toolFetch({
                                ruta: 'rt_generar_factura',
                                method: 'POST',
                                body: {...form.data, timbrar: 1},
                            }, (response) => {
                                if (response.estado == 1) {
                                    notie.alert({
                                        type: 'success',
                                        text: `Factura generada correctamente.`,
                                        time: 3,
                                        position: 'top'
                                    })
                                    setTimeout(() => {
                                        toggle()
                                    }, 300);
                                    window.location.reload();
                                }else if (response.estado == 0) {
                                    setTimeout(() => {
                                        toggle()
                                    }, 300);
                                    setTimeout(() => {
                                        notie.alert({
                                            type: 'warning',
                                            text: response.err != '' ? response.err : response.title,
                                            time: 3,
                                            position: 'top'
                                        })
                                    }, 325);
                                }
                            })
                        }, 
                        cancelCallback: function (value){
                            setTimeout(() => {
                                toggle()
                            }, 300);
                        }
                    })
                }else{
                    setTimeout(() => {
                        setModal(!modal)
                    }, 300);
                    setTimeout(() => {
                        notie.alert({
                            type: 'warning',
                            text: response.err != '' ? response.err : response.title,
                            time: 3,
                            position: 'top'
                        })
                    }, 325);
                }
            }
        });
    }

    useEffect(() => {
        let isS = true
        if (!esEditar) {
            if (isS) {
                setForm({})
            }
            return
        }

        Tools.toolFetch({
            ruta: 'rt_empresa_detalle',
            method: 'POST',
            body: { empresa: params.empresa || '' },
        }, (response) => {
            let datos = response.datos

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (isS) {
                setForm(datos)
            }
        })

        return () => isS = false
    }, [esEditar])

    return (
        <Fragment>
            <div className='col-sm-12 mb-1 mt-3'>
                <Breadcrumb title={"Instaladores"} parent={"Detalle"} />                
            </div>
            
            <div className="container-fluid">
                <div className='row'>
                    <form id='datos_fiscales' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                        <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/facturas')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>NUEVA FACTURA</strong></h4>
                                <span></span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="listSol">Folio de la solicitud<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <SelectFetch
                                                        id={'listSol'}
                                                        name={'listSol'}
                                                        ruta='rt_ctg_sol'
                                                        label={'Folio de la solicitud'}
                                                        placeholder={'Seleccionar o buscar folio de la solicitud'}
                                                        onChange={(datos) => {
                                                            onSelectFolio(datos.value)
                                                        }}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios listSol" style={{ display: 'none' }}><strong>Falta completar (Folio de la solicitud)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="listDF">Datos fiscales del emisor<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <select className="form-control form-control-md border border-dark" data-label="Datos fiscales" placeholder="Datos fiscales del emisor" id="listDF" name="listDF">
                                                        {datosFiscalesEm?.datosFiscalesEm ? 
                                                            <option value={datosFiscalesEm?.datosFiscalesEm[0].value}>{datosFiscalesEm?.datosFiscalesEm[0].label}</option>
                                                        :
                                                            <option value={""}>Datos fiscales del emisor</option>
                                                        }
                                                    </select>
                                                </div>
                                                <span className="text-danger txtVacios listDF" style={{ display: 'none' }}><strong>Falta completar (Datos fiscales del emisor)</strong></span>
                                            </div>
                                            <div className="col-md-12 mb-2 mt-2  d-none" id={'oculto-header-0'}>
                                                <h6><strong>DATOS DE FACTURACION DEL RECEPTOR</strong></h6>
                                            </div>
                                            <div className="col-md-12 mb-3 d-none" id={'oculto-0'}>
                                                <label htmlFor="listDatosFiscalesRem">Datos fiscales<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Datos fiscales"
                                                        placeholder="Seleccionar datos fiscales"
                                                        id="listDatosFiscalesRem"
                                                        name="listDatosFiscalesRem"
                                                        tipoValue={1}
                                                        datos={datosFiscales?.listDatosFiscales ? datosFiscales.listDatosFiscales : ''}
                                                        defaultValue={datosFiscales?.ID_DATOS ? datosFiscales?.ID_DATOS : ''}
                                                        onChange={(e) => {onSelectDatosFiscales(e.e.target.value)}}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios listDatosFiscalesRem" style={{ display: 'none' }}><strong>Falta completar (Datos fiscales)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3 d-none" id={'oculto-1'}>
                                                <label htmlFor="inputRazonSocial">Razón social<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control form-control-md border border-dark"
                                                        data-label="Grupo"
                                                        id="inputRazonSocial"
                                                        name="inputRazonSocial"
                                                        autoComplete='off'
                                                        type="text"
                                                        placeholder='Razón social'
                                                        maxLength={100}
                                                        defaultValue={datosFiscales?.RAZON_SOCIAL ? datosFiscales.RAZON_SOCIAL : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios inputRazonSocial" style={{ display: 'none' }}><strong>Falta completar (Razón social)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3 d-none" id={'oculto-2'}>
                                                <label htmlFor="inputRFC">RFC<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control form-control-md border border-dark"
                                                        data-label="Grupo"
                                                        id="inputRFC"
                                                        name="inputRFC"
                                                        autoComplete='off'
                                                        type="text"
                                                        placeholder='RFC'
                                                        maxLength={13}
                                                        defaultValue={datosFiscales?.RFC ? datosFiscales.RFC : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios inputRFC" style={{ display: 'none' }}><strong>Falta completar (RFC)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3 d-none" id={'oculto-3'}>
                                                <label htmlFor="inputCodigoPostal">Código Postal<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control form-control-md border border-dark"
                                                        data-label="Grupo"
                                                        id="inputCodigoPostal"
                                                        name="inputCodigoPostal"
                                                        autoComplete='off'
                                                        type="text"
                                                        placeholder='Código Postal'
                                                        maxLength={5}
                                                        defaultValue={datosFiscales?.CODIGO_POSTAL ? datosFiscales.CODIGO_POSTAL : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios inputCodigoPostal" style={{ display: 'none' }}><strong>Falta completar (Código Postal)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3 d-none" id={'oculto-4'}>
                                                <label htmlFor="inputCorreo">Correo electrónico<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control form-control-md border border-dark"
                                                        data-label="Grupo"
                                                        id="inputCorreo"
                                                        name="inputCorreo"
                                                        autoComplete='off'
                                                        type="text"
                                                        placeholder='Correo electrónico'
                                                        maxLength={100}
                                                        defaultValue={datosFiscales?.CORREO ? datosFiscales.CORREO : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios inputCorreo" style={{ display: 'none' }}><strong>Falta completar (Correo electrónico)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3 d-none" id={'oculto-5'}>
                                                <label htmlFor="inputUsoCFDI">Uso de CFDI<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Datos fiscales"
                                                        placeholder="Uso de CFDI"
                                                        id="inputUsoCFDI"
                                                        name="inputUsoCFDI"
                                                        tipoValue={1}
                                                        datos={catalogos?.inputUsoCFDI ? catalogos.inputUsoCFDI : ''}
                                                        defaultValue={datosFiscales?.CLAVE_USO_CFDI ? datosFiscales?.CLAVE_USO_CFDI : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios inputUsoCFDI" style={{ display: 'none' }}><strong>Falta completar (Uso de CFDI)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3 d-none" id={'oculto-6'}>
                                                <label htmlFor="inputRegimenFiscal">Régimen fiscal<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Datos fiscales"
                                                        placeholder="Régimen fiscal"
                                                        id="inputRegimenFiscal"
                                                        name="inputRegimenFiscal"
                                                        tipoValue={1}
                                                        datos={catalogos?.inputRegimenFiscal ? catalogos.inputRegimenFiscal : ''}
                                                        defaultValue={datosFiscales?.CLAVE_REG_FISCAL ? datosFiscales?.CLAVE_REG_FISCAL : ''}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios inputRegimenFiscal" style={{ display: 'none' }}><strong>Falta completar (Régimen fiscal)</strong></span>
                                            </div>
                                            <div className="col-md-12 d-none" id={'oculto-7'}>
                                                <div className="justify-content-end d-flex">
                                                    <button
                                                        className="btn btn-purple d-flex align-items-center"
                                                        type="button"
                                                        onClick={() => {generarFactura()}}>
                                                        Generar factura
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true} backdrop={'static'}>
                    <ModalHeader toggle={toggle}>Generar factura</ModalHeader>
                    <ModalBody>
                        <div className='modal-body' style={{fontSize: "1.5rem"}}>
                            <div className='row d-flex justify-content-center'>
                                <div className='col-lg-5 col-12 mb-3'>
                                    <div className='loader-2 my-0 mx-auto' style={{fontSize: "0.5rem"}}>
                                    </div>
                                    <p className='text-center my-4' style={{fontSize: "1.4rem"}}>Generando factura...</p>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
            </Modal>
        </Fragment>)
}

export default Default