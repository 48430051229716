import React, { Fragment, useEffect, useState } from 'react';
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../../components/common/breadcrumb';
import LoaderSeccion from '../../components/common/loader_secciones';
import Dropzone from '../common/dropzone';
import Select from '../common/select-basic'

const Default = ({
    esPrincipal,
    esEditar
}) => {
    const navigate = useNavigate()
    const params = useParams();
    const [form, setForm] = useState({});

    const guardar = () => {
        let formEmpresa = Tools.getForm('formEmpresa', { claveEtiquetas: '.txtVacios', ignore: ['tel_contacto'] })

        if (formEmpresa.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formEmpresa.stringIsEmpty})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: esEditar ? 'rt_empresas_editar' : 'rt_empresas_nueva',
            method: 'POST',
            body:  esEditar ? {
                ...formEmpresa.data,
                empresa: params.empresa || ''  ,
                id: form.ID_EM          
            } : {
                ...formEmpresa.data,
                empresa: params.empresa || ''            
            } ,
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Este contacto ya existe intente con otro correo`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (!esEditar) {
                setTimeout(() => navigate('/empresa'), 2000)
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Registro realizado con exito.`,
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    useEffect(() => {
        let isS = true
        if (!esEditar) {
            if (isS) {
                setForm({})
            }
            return
        }

        Tools.toolFetch({
            ruta: 'rt_empresa_detalle',
            method: 'POST',
            body: { empresa: params.empresa || '' },
        }, (response) => {
            let datos = response.datos

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (isS) {
                setForm(datos)
            }
        })

        return () => isS = false
    }, [esEditar])

    return (
        <Fragment>
            <div className='col-sm-12 mb-1 mt-3'>
                <Breadcrumb title={"CARTA PORTE"} parent={"Nuevo"} />                
            </div>
            
            <div className="container-fluid">
                <div className='row'>
                    <form id='formEmpresa' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                        <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/carta-porte/')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>NUEVA CARTA PORTE</strong></h4>
                                <span></span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Nombre carta<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control"
                                                        id="txt_empresa" name="txt_empresa"
                                                        type="text"
                                                        autoComplete='off'
                                                        placeholder="Nombre instalador"
                                                        defaultValue={form.NOMBRE_EM}
                                                        maxLength={30}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_empresa" style={{ display: 'none' }}><strong>Falta completar (Nombre comercial)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationTooltip02">Versión<span className="text-danger">*</span></label>
                                                <div className="input-group input-gpack2">
                                                    <input className="form-control"
                                                        id="txt_empresa" name="txt_empresa"
                                                        type="text"
                                                        autoComplete='off'
                                                        placeholder="Nombre instalador"
                                                        defaultValue={form.NOMBRE_EM}
                                                        maxLength={30}
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_empresa" style={{ display: 'none' }}><strong>Falta completar (Nombre comercial)</strong></span>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationTooltip02">Documento<span className="text-danger">*</span></label>
                                                <Dropzone
                                                    inputContent={"Subir documento en .pdf"}
                                                    /* initialFiles={prodServ ? '/pro-serv-img/' + prodServ.ID_PS + '/' + prodServ.IMG_DETALLE_URL : null}
                                                    fileName={prodServ ? prodServ.IMG_DETALLE_PS : ''}
                                                    onImg={(datos) => { setImg((s) => ({ ...s, statusDatelle: datos.status, imgDetalle: datos.file })) }} */
                                                />
                                                
                                            </div>
                                            <div className="col-md-12">
                                                <div className="justify-content-end d-flex">
                                                    <button
                                                        className="btn btn-purple d-flex align-items-center"
                                                        type="button"
                                                        onClick={(e) => guardar()} >
                                                        Guardar carta
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default